@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;900&display=swap');

:root {
  --background-main: #ffe9d9;
  /* --background-dark: #000fb7; */
  --background-dark: #000fff;
  --text-color: #000;
  --dark: #02230B;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-main);
  font-family: 'Inconsolata', monospace;
  color: var(--text-color);
  cursor: none;
  overflow: hidden;
  transition: background-color .25s;
  width: 100vw;
  height: 100vh;
}
body.dark {
  background-color: var(--background-dark);
  /* background-color: #F49BA7; */
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  filter: invert(1);
  mix-blend-mode: difference;
  cursor: none;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 4vw;
  line-height: 1.2em;
  font-weight: 300;
  filter: invert(1);
  mix-blend-mode: difference;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  height: 100vh;
  flex-direction: row;
  /* max-width: 800px; */
  margin: 0 auto;
  text-align: center;

  width: 100%;
  /* height: 100%; */
  max-width: 1200px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10vw;
}
.home-visual {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  margin-left: -16vw;
}

/* @media (max-width: 1080px) {
  .container {
    width: 40%;
    margin-left: 10%;
  }
} */

@media (max-width: 479px) {
  * {
    cursor: default;
  }
  main {
    flex-direction: column-reverse;
    max-width: 100%;
  }
  .home-visual {
    width: 100%;
    height: 50%;
    margin-left: -35vw;
  }
  .container {
    margin-bottom: 5vw;
  }
  h1 {
    font-size: 10vw;
  }
  .cursor {
    display: none;
  }
}

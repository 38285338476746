.cursor {
  mix-blend-mode: darken;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.25s;
}

:root:has(a:hover) .cursor {
  transform: scale(0.5);
}

:root:has(.girl-svg:hover) .cursor {
  transform: scale(1.25);
}

.rotate-cursor {
  transform-origin: 50% 50%;
  animation: move 20s linear infinite;
}

.rotate-cursor path {
  animation: pathMove 20s linear infinite;
}

@keyframes move {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pathMove {
  0% {
    d: path(
      'M492.7 188.9C535.8 263.5 547.4 356.5 510 425.7C472.6 495 386.3 540.5 304.9 537.7C223.5 534.8 147 483.7 118.1 419.3C89.2999 355 108 277.5 146.3 205.8C184.5 134 238.7 101.9 304.9 97.0004C371.2 92.1004 449.5 114.3 492.7 188.9Z'
    );
  }
  25% {
    d: path(
      'M416 239.2C456.5 303 499.5 371.5 480.6 428.2C461.7 485 380.8 530 297.8 531.3C214.8 532.5 129.7 490 93.6999 423.4C57.7999 356.8 71.0999 266.2 113.7 201.1C156.2 136 228.1 96.4997 283 106.3C337.8 116.2 375.6 175.3 416 239.2Z'
    );
  }
  50% {
    d: path(
      'M492.7 188.9C535.8 263.5 547.4 356.5 510 425.7C472.6 495 386.3 540.5 304.9 537.7C223.5 534.8 147 483.7 118.1 419.3C89.2999 355 108 277.5 146.3 205.8C184.5 134 242.3 67.9998 308.5 63.0998C374.8 58.1998 449.5 114.3 492.7 188.9Z'
    );
  }
  75% {
    d: path(
      'M416 239.2C456.5 303 499.5 371.5 480.6 428.2C461.7 485 380.8 530 297.8 531.3C214.8 532.5 129.7 490 93.6999 423.4C57.7999 356.8 71.0999 266.2 113.7 201.1C156.2 136 228.1 96.4997 283 106.3C337.8 116.2 375.6 175.3 416 239.2Z'
    );
  }
  100% {
    d: path(
      'M492.7 188.9C535.8 263.5 547.4 356.5 510 425.7C472.6 495 386.3 540.5 304.9 537.7C223.5 534.8 147 483.7 118.1 419.3C89.2999 355 108 277.5 146.3 205.8C184.5 134 238.7 101.9 304.9 97.0004C371.2 92.1004 449.5 114.3 492.7 188.9Z'
    );
  }
}

.box {
  position: absolute;
  cursor: grab;
  font-size: 40px;
  font-weight: 400;
  filter: invert(1);
  mix-blend-mode: difference;
}
.box.highlighted {
  font-weight: 900;
}
body.dark .box.highlighted {
  color: yellow;
}

.about-container * {
  user-select: none;
}

.about-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.social-links {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.social-links a {
  color: white;
  width: 50px;
  display: block;
  opacity: 0.7;
}
.social-links span {
  padding: 20px 10px;
  display: block;
}

@media (max-width: 479px) {
  .box {
    font-size: 20px;
  }
}

:root {
  --padding: 40px;
}

.navbar {
  padding: var(--padding);
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.navbar a {
  color: var(--text-color);
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-size: 16px;
}
.navbar a .shuffle-text {
  padding: 20px;
}

.shuffle-text svg {
  margin-left: 5px;
}

.navbar a.logo {
  font-size: 2rem;
  text-transform: capitalize;
}

.logo {
  position: absolute;
  left: var(--padding);
  top: calc(var(--padding) / 2);
  font-weight: 600;
}

.mobile-navbar {
  display: none;
}

.mobile-navbar a.logo {
  font-size: 1.5rem;
}

@media (max-width: 479px) {
  .navbar {
    display: none;
  }
  .mobile-navbar {
    display: block;
  }
}

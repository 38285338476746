
.girl-svg {
  height: 100%;
  filter: invert(1);
  mix-blend-mode: difference;
}

#dashed-line {
  stroke-dasharray: 8;
}

body.dark #dashed-line {
  animation: dash 60s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}
#menuToggle {
  display: block;
  position: absolute;
  top: calc(var(--padding) / 2);
  right: var(--padding);
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle .menu-icon {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: black;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.dark #menuToggle .menu-icon {
  background: #ffeb3b;
}

.dark #menuToggle.open .menu-icon {
  background: black;
}

#menuToggle .menu-icon:first-child {
  transform-origin: 0% 0%;
}

#menuToggle .menu-icon:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle.open .menu-icon {
  opacity: 1;
  transform: rotate(45deg) translate(-4px, -2px);
}

#menuToggle.open .menu-icon:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle.open .menu-icon:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: -47px 0 0 73px;
  padding: 50px;
  background: rgb(155 244 169 / 70%);
  transform-origin: 0% 0%;
  transform: translate(0, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  backdrop-filter: blur(8px);
}

#menu a {
  padding: 1rem 0;
  font-size: 2rem;
  color: black;
  filter: none;
  mix-blend-mode: unset;
}

#menu a svg {
  width: 20px;
  height: 20px;
}

#menuToggle.open #menu {
  transform: translate(-100%, 0);
}
